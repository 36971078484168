import React, { useEffect } from 'react';

function Callback(props) {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            // Здесь вы можете отправить код на ваш сервер или обменять его на токен напрямую
            console.log("Authorization code:", code);
        }
    }, []);

    return (
        <div>
            Обработка авторизации...
        </div>
    );
}

export default Callback;

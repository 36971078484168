import React from 'react';
import './App.css';
import Callback from './Callback';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import gitlabLogo from './assets/gitlab-logo.svg';

function App() {
  const gitlabClientID = "52cbf7a1e7015679cfc1750a186c7635375336d614004ea7ad91ec24c4ab1297";
  const redirectURI = "http://localhost:3000/callback"; // Установите это перенаправление в настройках вашего приложения GitLab

  const gitlabAuthUrl = `https://gitlab.bgaming-system.com/oauth/authorize?client_id=${gitlabClientID}&redirect_uri=${redirectURI}&response_type=code&scope=read_user`;

  return (
    <Router>
        <Routes>
            <Route path="/callback" element={<Callback />} />
            <Route path="/" element={
              <div className="App">
                <header className="App-header">
                  <img src={gitlabLogo} alt="GitLab" className="App-logo" />
                  <div className="App-title">FE Games Admin</div>
                  <div className="App-description">Добро пожаловать в панель управления FE Games. Авторизуйтесь через GitLab, чтобы продолжить.</div>
                  <button className="App-button" onClick={() => window.location.href = gitlabAuthUrl}>
                    Авторизоваться через GitLab
                  </button>
                </header>
              </div>
            } />
        </Routes>
    </Router>
  );
}

export default App;
